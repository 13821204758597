/* .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: antiquewhite;
    margin-bottom: 40px;
}

.logo { width: 80px; }

.util ul {
    display: flex;
    justify-content: space-between;
    align-items: center;
    column-gap: 12px;
}

.navbar {
    width: auto;
    background-color: aqua;
    display: flex;
    justify-content: space-between;
}

.nav-left {
    margin-right: 15px;
}

.nav-right {
    margin-left: 15px;
} */

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
}

@font-face {
  font-family: 'Freesentation-9Black';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2404@1.0/Freesentation-9Black.woff2') format('woff2');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'TTLaundryGothicB';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2403-2@1.0/TTLaundryGothicB.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Ownglyph_ryurue-Rg';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2405-2@1.0/Ownglyph_ryurue-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Ownglyph_ryuttung-Rg';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2405-2@1.0/Ownglyph_ryuttung-Rg.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'GangwonEdu_OTFBoldA';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'JalnanGothic';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_231029@1.1/JalnanGothic.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'YEONGJUSeonbiTTF';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/2403@1.0/YEONGJUSeonbiTTF.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.menu {
  background-color: white;
  box-shadow: none !important;
}

.menu__title {
  font-family: 'YEONGJUSeonbiTTF', sans-serif !important;
  margin-left: 100px !important;
}

.menuItems {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  text-wrap: nowrap;
  /* justify-content: space-between; */
}

.menuItem {
  font-size: 18px !important;
  color: black !important;
  font-family: 'GangwonEdu_OTFBoldA', sans-serif !important;
}

.menuUser {
  margin-right: 150px !important;
}

.mobileMenu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mobile__menu__title {
  font-family: 'YEONGJUSeonbiTTF', sans-serif !important;
  margin-left: 30px !important;
}

.mobile__menu__item {
  display: flex;
  flex-direction: row;
  align-items: center;
}