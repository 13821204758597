/* .form {
    width: 400px;
    margin: auto;
    padding: 36px 48px 48px 48px;
    background-color: #f2efee;
  
    border-radius: 11px;
    box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.15);
  }
  
  .login-title {
    padding: 15px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
  }
  
  .login-form {
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 16px;
  }
  
  .login-form label {
    display: block;
    margin-bottom: 8px;
  }
  
  .login-form input {
    width: 100%;
    padding: 1.2rem;
    border-radius: 9px;
    border: none;
  }
  
  .login-form input:focus {
    outline: none;
    box-shadow: 0 0 0 4px rgba(253, 242, 233, 0.5);
  }
  
  .btn--form {
    background-color: #f48982;
    color: #fdf2e9;
    align-self: end;
    padding: 8px;
  }
  
  .btn,
  .btn:link,
  .btn:visited {
    display: inline-block;
    text-decoration: none;
    font-size: 20px;
    font-weight: 600;
    border-radius: 9px;
    border: none;
  
    cursor: pointer;
    font-family: inherit;
  
    transition: all 0.3s;
  }
  
  .btn-login:hover {
    outline: 1px solid #f48982;
  }
  
  .btn--form:hover {
    background-color: #fdf2e9;
    color: #f48982;
  }
   */

@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
}


.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'NanumBarunGothic', sans-serif;
  font-size: 16px;
}

.join__form {
  margin-top: -30px;
  margin-bottom: 50px;
  width: 560px;
  max-width: 560px;
}

.join__form__title {
  width: fit-content;
  font-size: 40px;
  color: #0d6efd;
  font-weight: 900;
  cursor: pointer;
  /* text-shadow: 3px 4px 5px #898989; */
}

.form__label {
  color: #898989;
  font-weight: 400;
}

.btn__join {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border: 1px solid white;
}

.btn__join:hover {
  border: 1px solid #0d6efd;
  background-color: white;
  color: #0d6efd;
}