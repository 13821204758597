  @font-face {
    font-family: 'NanumBarunGothic';
    font-style: normal;
    font-weight: 400;
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
    src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
}

/* body {
    background: #007bff;
    background: linear-gradient(to right, #0062E6, #33AEFF);
    font-family: 'NanumBarunGothic', sans-serif;
} */

.content {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: 'NanumBarunGothic', sans-serif;
  font-size: 16px;
}

.login__form {
  margin-top: 0px;
  margin-bottom: 50px;
  width: 560px;
  max-width: 560px;
}

.login__form__title {
  font-size: 54px;
  color: #0d6efd;
  font-weight: 900;
  /* text-shadow: 3px 4px 5px #898989; */
}

.form__label {
  color: #898989;
  font-weight: 400;
}

.btn__login {
    font-size: 0.9rem;
    letter-spacing: 0.05rem;
    padding: 0.75rem 1rem;
    border: 1px solid white;
}

.btn__login:hover {
  border: 1px solid #0d6efd;
  background-color: white;
  color: #0d6efd;
}

.btn__login__join {
  width: fit-content;
  color: #898989;
  cursor: pointer;
  margin: 0 auto;
}
/* .btn-google {
    color: white !important;
    background-color: #ea4335;
}

.btn-facebook {
    color: white !important;
    background-color: #3b5998;
} */