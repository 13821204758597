/* 웹폰트 설정 */
@font-face {
  font-family: 'BMJUA';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_one@1.0/BMJUA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Noto Sans', sans-serif;
}

ul { list-style-type: none; }
a, .link {
  text-decoration: none;
  color: black;
  background-color: transparent;
  border: none;
}
button { cursor: pointer; }

/* 에디터 기본 스타일 */
.tiptap {
  padding: 1rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  min-height: 200px;
}

.tiptap > * + * { margin-top: 0.75em; }

.tiptap ul, .tiptap ol {
  padding: 0 1rem;
}

.tiptap h1, .tiptap h2, .tiptap h3, .tiptap h4, .tiptap h5, .tiptap h6 {
  line-height: 1.1;
}

.tiptap code {
  background-color: rgba(97, 97, 97, 0.15); /* 배경색을 조금 더 어둡게 */
  color: #282828; /* 글자색 */
  padding: 0.2rem 0.4rem; /* 코드 블록의 내부 여백 추가 */
  border-radius: 4px; /* 둥근 모서리 추가 */
  font-size: 0.9rem; /* 약간 작은 글자 크기 */
  font-family: "JetBrainsMono", monospace; /* 폰트 설정 */
}

.tiptap pre {
  background: #2e2e2e; /* 어두운 배경색 */
  border-radius: 0.5rem; /* 둥근 모서리 */
  color: #f8f8f2; /* 밝은 글자색 */
  font-family: "JetBrainsMono", monospace; /* 폰트 설정 */
  padding: 1rem 1.5rem; /* 내부 여백 추가 */
  overflow-x: auto; /* 긴 코드가 넘칠 때 스크롤 가능하게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
}

.tiptap pre code {
  background: none;
  color: inherit;
  font-size: 0.85rem; /* 약간 작은 글자 크기 */
  padding: 0;
  display: block;
  white-space: pre-wrap; /* 코드 블록 내에서 줄바꿈 유지 */
  line-height: 1.5; /* 줄 간격 조절 */
}

.tiptap mark {
  background-color: #fff3cd;
}

.tiptap img {
  height: auto;
  max-width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  /* transition: transform 0.3s ease; */
  /* outline: none; */
}

.tiptap img:focus {
  outline: 3px solid #007bff;
  /* transform: scale(1.05); */
}

.tiptap hr {
  margin: 1rem 0;
  border: none;
  border-top: 2px solid rgba(168, 129, 129, 0.1);
}

.tiptap blockquote {
  border-left: 2px solid rgba(13, 13, 13, 0.1);
  padding-left: 1rem;
}

.tiptap ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

.tiptap ul[data-type="taskList"] li {
  align-items: center;
  display: flex;
}

.tiptap ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.tiptap ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}

.tiptap-highlight {
  background-color: yellow; /* 기본 하이라이트 색상 */
  padding: 0 0.2rem; /* 강조된 텍스트 주위에 약간의 패딩 */
  border-radius: 0.2rem; /* 둥근 모서리 추가 */
}

/* 전역 관리 (에디터로 저장 후 Minutes 에서 확인 할 때의 CSS 를 일치시켜주기 위함) */
.minutes__form ul, .minutes__form ol {
  padding: 0 1rem;
}

.minutes__form h1, .minutes__form h2, .minutes__form h3, .minutes__form h4, .minutes__form h5, .minutes__form h6 {
  line-height: 1.1;
}

.minutes__form code {
  background-color: rgba(97, 97, 97, 0.15); /* 배경색을 조금 더 어둡게 */
  color: #282828; /* 글자색 */
  padding: 0.3rem 0.4rem; /* 코드 블록의 내부 여백 추가 */
  border-radius: 4px; /* 둥근 모서리 추가 */
  font-size: 0.9rem; /* 약간 작은 글자 크기 */
  font-family: "JetBrainsMono", monospace; /* 폰트 설정 */
}

.minutes__form pre {
  background: #2e2e2e; /* 어두운 배경색 */
  border-radius: 0.5rem; /* 둥근 모서리 */
  color: #f8f8f2; /* 밝은 글자색 */
  font-family: "JetBrainsMono", monospace; /* 폰트 설정 */
  padding: 1rem 1.5rem; /* 내부 여백 추가 */
  overflow-x: auto; /* 긴 코드가 넘칠 때 스크롤 가능하게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* 그림자 효과 추가 */
}

.minutes__form pre code {
  background: none;
  color: inherit;
  font-size: 0.85rem; /* 약간 작은 글자 크기 */
  padding: 0;
  display: block;
  white-space: pre-wrap; /* 코드 블록 내에서 줄바꿈 유지 */
  line-height: 1.5; /* 줄 간격 조절 */
}

.minutes__form mark {
  background-color: #fff3cd;
  padding: 0.25rem; /* 강조된 텍스트 주위에 약간의 패딩 */
  border-radius: 0.25rem; /* 둥근 모서리 추가 */
}

.minutes__form img {
  height: auto;
  max-width: 100%;
  border-radius: 0.25rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  margin: 1rem 0;
  /* transition: transform 0.3s ease; */
  /* outline: none; */
}

.minutes__form img:focus {
  outline: 3px solid #007bff;
  /* transform: scale(1.05); */
}

.minutes__form blockquote {
  border-left: 2px solid rgba(13, 13, 13, 0.1);
  padding-left: 1rem;
}

.minutes__form ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

.minutes__form ul[data-type="taskList"] li {
  align-items: center;
  display: flex;
}

.minutes__form ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

.minutes__form ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}

.editor {
  background-color: #ffffff;
  border: 1px solid #ebebeb;
  border-radius: 0.75rem;
  color: #0d0d0d;
  display: flex;
  flex-direction: column;
  max-height: 26rem;
  height: auto;
  overflow-y: auto;
  padding: 0;
}

.editor [contenteditable="true"]:focus { outline: none; }

.editor__header {
  align-items: center;
  background: linear-gradient(135deg, #e0e0e0, #cfcfcf);
  border-bottom: 2px solid #d0d0d0;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  display: flex;
  flex-wrap: wrap;
  padding: 0.5rem;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: sticky;
  top: 0; /* 페이지 상단에 고정 */
  z-index: 1000; /* 다른 요소들 위에 위치하도록 설정 */
}

.editor__content {
  flex: 1 1 auto;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1.25rem 1rem;
  -webkit-overflow-scrolling: touch;
}

.editor__footer {
  align-items: center;
  border-top: 3px solid #0d0d0d;
  color: #0d0d0d;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  justify-content: space-between;
  padding: 0.25rem 0.75rem;
  white-space: nowrap;
}

.editor__status {
  align-items: center;
  border-radius: 5px;
  display: flex;
}

.editor__status::before {
  background: rgba(13, 13, 13, 0.5);
  border-radius: 50%;
  content: " ";
  display: inline-block;
  height: 0.5rem;
  margin-right: 0.5rem;
  width: 0.5rem;
}

.editor__status--connecting::before { background: #616161; }
.editor__status--connected::before { background: #b9f18d; }

.editor__name button {
  background: none;
  border: none;
  border-radius: 0.4rem;
  color: #0d0d0d;
  font: inherit;
  font-size: 12px;
  font-weight: 600;
  padding: 0.25rem 0.5rem;
}

.editor__name button:hover {
  background-color: #0d0d0d;
  color: #454545;
}

.collaboration-cursor__caret {
  border-left: 1px solid #0d0d0d;
  border-right: 1px solid #0d0d0d;
  margin-left: -1px;
  margin-right: -1px;
  pointer-events: none;
  position: relative;
}

.collaboration-cursor__label {
  border-radius: 3px;
  color: #0d0d0d;
  font-size: 12px;
  font-weight: 600;
  left: -1px;
  padding: 0.1rem 0.3rem;
  position: absolute;
  top: -1.4em;
  user-select: none;
  white-space: nowrap;
}

.resize-handle {
  display: none !important;
  position: absolute;
  right: -8px;
  bottom: 8px;
  width: 14px;
  height: 14px;
  background-color: #007bff;
  border-radius: 50%;
  cursor: nwse-resize;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 10;
}

/* .resize-handle:before {
  content: '';
  width: 8px;
  height: 8px;
  background-color: #ffffff;
  border-radius: 50%;
} */

img:focus + .resize-handle {
  display: flex !important;
}

/* .resize-handle:hover {
  background-color: #0056b3;
} */

.context-menu {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  padding: 8px 0;
  z-index: 1000;
  width: 120px;
}

.context-menu-item {
  padding: 8px 12px;
  cursor: pointer;
  color: #333;
  font-size: 14px;
}

.context-menu-item:hover {
  background-color: #f0f0f0;
  color: #000;
}


/* tooltip */
.menu-item-wrapper {
  position: relative;
  display: inline-block;
}

.menu-item {
  background-color: transparent;
  border: none;
  border-radius: 0.4rem;
  color: #6a6a6a;
  cursor: pointer;
  height: 2rem;
  margin-right: 0.5rem;
  padding: 0.5rem;
  width: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.menu-item svg {
  fill: currentColor;
  height: 1.5rem;
  width: 1.5rem;
}

.menu-item:hover,
.menu-item.is-active {
  background-color: #007bff;
  color: #fff;
  transform: translateY(-2px);
}

/* 툴팁 스타일링 */
.tooltip {
  position: fixed; /* fixed로 변경하여 화면에 고정되도록 설정 */
  background-color: #333;
  color: #fff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  font-size: 0.85rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  white-space: nowrap;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s ease, transform 0.2s ease;
  z-index: 1000;
}

.menu-item-wrapper:hover .tooltip {
  opacity: 1;
  transform: translateY(5px); /* 고정된 위치에서 위로 이동 */
}
