/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

/* .btnSave {
  margin: 5px;
}

.btnCancel {
  margin: 5px;
} */

.customTable {
  min-width: 576px;
}

.selectMenu {
  min-width: 105px;
}

.container {
  margin-top: 90px;
}

/* .container {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
} */

/* body, html {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
} */

/* Header CSS */
/* .css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  z-index: 1000 !important;
} */


/* HOME PAGE CSS */
@font-face {
  font-family: 'GangwonEdu_OTFBoldA';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2201-2@1.0/GangwonEdu_OTFBoldA.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.login__form {
  font-family: 'GangwonEdu_OTFBoldA';
}

.join__form {
  font-family: 'GangwonEdu_OTFBoldA';
}

.member__form {
  font-family: 'GangwonEdu_OTFBoldA';
}

.group__form {
  font-family: 'GangwonEdu_OTFBoldA';
}

.edit__user__form {
  font-family: 'GangwonEdu_OTFBoldA';
}

.attendanceCountContainer {
  font-family: 'GangwonEdu_OTFBoldA';
}

.absenteeContainer {
  font-family: 'GangwonEdu_OTFBoldA';
}

.attendance__list {
  font-family: 'GangwonEdu_OTFBoldA';
}

.attendance__check__form {
  font-family: 'GangwonEdu_OTFBoldA';
  font-size: medium;
}

.attendanceCountContainer {
  text-align: center;
}

.absenteeContainer {
  text-align: center;
}

.absenteeBox {
  width: 60px;
  display: inline-block;
  border: 1px solid #c5c5c5;
  border-radius: 15px;
  text-align: center;
  margin: 3px;
  padding: 3px;
}

.absenteeTitle {
  margin-bottom: 20px;
}

.absentee {
  font-family: 'GangwonEdu_OTFBoldA' !important;
  margin-top: 2px !important;
  font-size: 14px !important;
}

/* CreateGroup */
.btnWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.btnSave {
  width: 100%;
  margin-left: 2px !important;

}

.btnCancel {
  width: 100%;
  margin-right: 2px !important;
  background-color: #c5c5c5;
  border: 1px solid #c5c5c5;
}

.btnCancel:hover {
  background-color: #909090;
  border: 1px solid #909090;
}

.btnCancel:active {
  background-color: #7d7d7d !important;
  border: 1px solid #7d7d7d !important;
}

.btnCancel:focus {
  background-color: #7d7d7d !important;
  border: 1px solid #7d7d7d !important;
}

/* CreateMember */
.genderSelect {
  text-align: center;
}

/* EditAttendanceCheck */
.btnWrapperEditAttendance {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
}

.btnSaveEditAttendance {
  width: 100%;
  max-width: 120px;
  margin-left: 2px !important;
}

.btnCancelEditAttendance {
  width: 100%;
  max-width: 120px;
  margin-right: 2px !important;
  background-color: #c5c5c5;
  border: 1px solid #c5c5c5;
}

.btnCancelEditAttendance:hover {
  background-color: #909090;
  border: 1px solid #909090;
}

.btnCancelEditAttendance:active {
  background-color: #7d7d7d !important;
  border: 1px solid #7d7d7d !important;
}

.btnCancelEditAttendance:focus {
  background-color: #7d7d7d !important;
  border: 1px solid #7d7d7d !important;
}

/* AttendanceList */
.btnWrapperAttendanceList {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  margin-bottom: 30px;
}

.btnSaveAttendanceList {
  width: 100%;
  max-width: 120px;
}

/* DataGrid 아래 글자 정렬 */
.MuiTablePagination-selectLabel {
  margin: 0;
}

.MuiTablePagination-displayedRows {
  margin: 0;
}

/* CreateMinutes */
.minutes__container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.minutes__create__form {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
}

/* .minutes__create__btn__group {
  width: 100%;
} */

/* .minutes__form {
  margin-top: 50px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 700px;
  border-radius: 15px;
  box-shadow: 0 4px 5px -1px rgba(0,0,0,.1);
  padding: 10px;
} */


/* DetailMinutes */

.detail__minutes__container {
  /* width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; */
  max-width: 800px;
  /* 컨테이너의 최대 너비 설정 */
  margin: 2rem auto;
  /* 중앙 정렬 및 위아래 여백 */
  padding: 2rem;
  background-color: #ffffff;
  /* 흰색 배경 */
  border-radius: 10px;
  /* 둥근 모서리 */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  /* 부드러운 그림자 */
  font-family: 'Arial', sans-serif;
  /* 기본 글꼴 설정 */
  word-wrap: break-word;
  /* 긴 단어나 URL이 컨테이너 밖으로 넘치지 않도록 설정 */
}

.detail__minutes__container img {
  max-width: 100%;
  /* 부모 요소의 너비를 넘지 않도록 설정 */
  height: auto;
  /* 원본 이미지의 비율을 유지하면서 크기 조정 */
  border-radius: 8px;
  /* 이미지 모서리를 둥글게 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* 그림자 효과 추가 */
  margin: 1rem 0;
  /* 이미지 위아래에 적절한 여백 추가 */
}

.detail__minutes__container .content__body {
  overflow: hidden;
  /* 컨텐츠가 부모 요소를 넘지 않도록 설정 */
}

.minutes__form h4 {
  font-size: 1.5rem;
  /* 적당한 크기의 제목 폰트 */
  color: #333;
  /* 진한 회색 텍스트 색상 */
  padding: 0.5rem 1rem;
  /* 제목 주위에 패딩 추가 */
  border-bottom: 2px solid #007bff;
  /* 하단에 강조선 추가 */
  margin-bottom: 0.4rem;
  /* 제목과 아래 요소 사이의 간격 */
  font-weight: bold;
  /* 굵은 폰트 설정 */
  background-color: #fff;
  /* 밝은 배경색 추가 */
  text-align: left;
  /* 왼쪽 정렬 */
}

.content__header {
  /* display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center; */
  display: flex;
  justify-content: space-between;
  /* 작성자와 날짜를 양쪽 끝으로 배치 */
  color: #555;
  /* 텍스트 색상 */
  font-size: 0.9rem;
  margin-bottom: 0.8rem;
}

.content__header__author {
  /* width: 20px !important; */
}

.content__header__created__time {
  text-align: right;
}

.content__body {
  /* min-height: 300px; */
  line-height: 1.6;
  /* 줄 간격 설정 */
  color: #444;
  /* 텍스트 색상 */
  font-size: 1rem;
  /* 기본 글자 크기 */
  word-wrap: break-word;
  /* 긴 단어가 넘치지 않도록 설정 */
}

.content__footer {
  /* display: flex;
  flex-direction: row;
  justify-content: center; */
  text-align: center;
  /* 하단 버튼을 중앙 정렬 */
  margin-top: 2rem;
  /* 위쪽 여백 */
}

.content__footer button {
  background-color: #007bff;
  /* 버튼 배경색 */
  color: white;
  /* 버튼 텍스트 색상 */
  padding: 0.75rem 1.5rem;
  /* 버튼 내부 여백 */
  border: none;
  border-radius: 5px;
  /* 둥근 모서리 */
  cursor: pointer;
  /* 클릭 가능한 커서 */
  transition: background-color 0.3s ease;
  /* 배경색 변경 애니메이션 */
}

.content__footer button:hover {
  background-color: #0056b3;
  /* 마우스 오버 시 배경색 변경 */
}

hr {
  border: none;
  border-top: 1px solid #b4b4b4;
  /* 얇은 구분선 */
  margin-bottom: 2rem 0;
}